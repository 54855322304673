import React from 'react';
import { Layout, Avatar, Space, Menu, PageHeader, Dropdown } from 'antd';
import {  LoadingOutlined, ImportOutlined, UserOutlined  } from '@ant-design/icons';
const { Header } = Layout;

const usermenu = props => {
    return (
        <Menu>
          <Menu.Item key="-2" icon={<UserOutlined />}>
            <a key="-2" onClick={(e) => props.menugo(e.key)} href="/settings">Профиль</a>
          </Menu.Item>
        </Menu>
      )
};

const Global = props => {
    
    return (
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
            <PageHeader
                className="site-page-header"
                title=
                    {!props.state.loading ? 
                        <>{props.state.activemenu > 0 ? 
                            props.state.proj[props.state.activeproj[0]].name 
                        :
                            props.state.menu[props.state.activemenu]
                        }</>
                    :
                        <>Обновление</>
                    }
                subTitle=
                    {props.state.activemenu > 0 ? 
                        props.state.menu[props.state.activemenu] 
                    :
                        ''
                    }
                avatar=
                    {!props.state.loading ? 
                        props.state.activemenu > 0 ? 
                            { size:32, icon:props.icons(props.state.proj[props.state.activeproj[0]].icon)}
                        :
                            { size:32, icon:props.icons(props.state.activemenu)}
                    :
                        { size:32, icon:<LoadingOutlined />} 
                    }
                extra={[
                    <Space wrap key="loadkey">
                        {props.state.profile.name ? 
                            <>
                                <Dropdown overlay={usermenu(props)} trigger={['click']} >
                                    <Space wrap>
                                        <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                    </Space>
                                </Dropdown>
                            </>
                        :
                            <>
                                Загрузка...
                                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                            </>
                        }
                    </Space>,
                ]}
            />
        </Header>
    )
};

export default Global;