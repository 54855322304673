import React from 'react';
import { Link } from "react-router-dom";
import { Menu, Divider, Drawer } from 'antd';
import { HomeOutlined, ImportOutlined, SettingOutlined, MenuOutlined, ArrowRightOutlined } from '@ant-design/icons';

const Global = props => {
    const showDrawer = () => {
        props.setState( {leftmenu: 1} );
    };
    const onClose = () => {
        props.setState( {leftmenu: 0} );
    };

    return (
        <React.Fragment>
            <span onClick={showDrawer} className="menubutton">
                {props.state.leftmenu ? 
                    <ArrowRightOutlined />
                :
                    <MenuOutlined />
                }
            </span>
            <Drawer
                closable={false}
                placement="left"
                onClose={onClose}
                visible={props.state.leftmenu}
                width={300}
                className="menuleft"
            >
                <div className="logo" />
                <p className="profile_name">{props.state.profile.name}</p>
                <Menu 
                    mode="inline" 
                    defaultSelectedKeys={props.state.activemenu} 
                    selectedKeys=
                        {props.state.activemenu === '1' ? 
                            props.state.activeproj[1] 
                        :
                            props.state.activemenu
                        } 
                    defaultOpenKeys={['1']}
                >
                    <Menu.Item key="-1" onClick={(e) => props.menugo(e.key)} icon={<HomeOutlined />}><Link to="/">Главная</Link></Menu.Item>
                    { props.state.proj.map( list => <Menu.Item key={list.id} onClick={(e) => props.menugo(e.key)} icon={props.icons(list.icon)}><Link to={`/${list.url}`}>{list.name}</Link></Menu.Item>) }
                    <Divider className="dividermenu" />
                    <Menu.Item key="-2" onClick={(e) => props.menugo(e.key)} icon={<SettingOutlined />}><Link to="/settings">Настройки</Link></Menu.Item>
                    <Menu.Item key="0" onClick={(e) => props.menugo(e.key)} icon={<ImportOutlined />}><Link to="/login">Выйти</Link></Menu.Item>
                </Menu>
            </Drawer>
        </React.Fragment>

    )
};

export default Global;