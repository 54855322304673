import React from 'react';
import { Layout, Spin } from 'antd';
import {  LoadingOutlined  } from '@ant-design/icons';

const Global = props => {
    return (
        <Layout className="loadpage" style={{ minHeight: "100vh" }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} />
        </Layout>
    )
};

export default Global;