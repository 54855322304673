//@ Библиотеки
import { hot } from 'react-hot-loader/root';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import React, { Component, Suspense, lazy } from 'react';

//@ Компоненты
import { Layout, ConfigProvider, Spin, Tabs, Typography, Modal, Tooltip, notification } from 'antd';
import { HomeOutlined, FunnelPlotOutlined, AppstoreOutlined, SettingOutlined, TrophyOutlined, BarsOutlined, GlobalOutlined, FireOutlined, DollarCircleOutlined, FolderOpenOutlined, TeamOutlined, ShoppingCartOutlined, ImportOutlined, DownloadOutlined, LoadingOutlined, CalculatorOutlined, CommentOutlined, OrderedListOutlined } from '@ant-design/icons';

//@ Локальные настройки
import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';

//@ Стили
import 'antd/dist/antd.css';
import './App.css';

//@ Страницы
import GlobLoadpage from './components/Global/Loadpage';
import GlobLeftmenu from './components/Global/Leftmenu';
import GlobHeader from './components/Global/Header';
const Auth = lazy(() => import('./components/Auth/Auth'));
const HomeHome = lazy(() => import('./components/Home/Home')); 
const CliClients = lazy(() => import('./components/Clients/Clients')); 
const CliClient = lazy(() => import('./components/Clients/Client')); 
const CliServices = lazy(() => import('./components/Clients/Services')); 
const CliSites = lazy(() => import('./components/Clients/Sites')); 
const Sites = lazy(() => import('./components/Sites/Sites')); 
const Reviews = lazy(() => import('./components/Reviews/Reviews')); 
const News_mz = lazy(() => import('./components/News_mz/News_mz')); 
const CliView = lazy(() => import('./components/Clients/View')); 
const CliSocials = lazy(() => import('./components/Clients/Socials')); 
const TasksTasks = lazy(() => import('./components/Tasks/Tasks'));
const ResResources = lazy(() => import('./components/Resources/Resources'));
const ShopShop = lazy(() => import('./components/Shop/Shop'));
const CalcInst = lazy(() => import('./components/Calc/Instagram'));
const DomainsDomains = lazy(() => import('./components/Domains/Domains'));
const AuthorsAuthors = lazy(() => import('./components/Authors/AuthorsAuthors'));
const SetProfile = lazy(() => import('./components/Settings/Profile'));
const SetEmployees = lazy(() => import('./components/Settings/Employees'));
const SetGroups = lazy(() => import('./components/Settings/Groups'));
const SetLogs = lazy(() => import('./components/Settings/Logs'));
const Deals = lazy(() => import('./components/Deals/Deals'));

const { Content } = Layout;
const TabPane = Tabs.TabPane;
const { Link } = Typography;

const notif = (type,title, text) => {
  notification[type]({
    message: title,
    description:text,
    placement:'bottomRight',
  });
};

const getByStatus = (status, items) => items.filter(quote => quote.status === status.id);

class App extends Component {
  constructor(props) {
		super(props);

    this.state = {
      auth: 0,
      error: ['',''],
      menu: {"-1":'Главная',"-2":'Настройки',"1":'Проект',"0":'Выйти'},
      profile: {'login':'','name':'','ava':''},
      proj: [],
      proj1: [[],[],['','',''],'1','',[],{},[],[],'1'],        // 0-список категорий,1-,2-категории,3-Tab,4-дата для папки,5-список ресурсов,6-ресурс для редактирования,7-владельцы,8-теги,9-владелец
      proj2: ['1',[],[],[],{}],                    // 0-Tab,1-список категорий,2-список всех товаров,3-список неопубликованных товаров,4-товар для редактирования
      proj3: ['1',[],[],[],'[]',[]],                                // 0-Tab,1-список задач,2-список юзеров,3-форма для задачи,4-список задачи
      proj4: ['1',[],[],[],{},[],[],{},{},[],[]],       // 0-Tab,1-список категорий,2-список всех услуг,3-список клиентов,4-форма для услуги,5-картинки,6-список сайтов,7-форма для сайта,8-форма для клиента,9-мини список сайтов,10-документ для редактирования
      proj5: ['1',[],[]],                                              //0-Результат,1-Инста
      proj6: ['1',[]],                                              // 0-Tab,1-список сайтов,2-форма для сайта
      proj7: ['1',[]],                                              // 0-Tab,1-список...
      setting: ['1'],                               // 0- Tab,
      users: [], 
      groups: [],
      usersitem: [],
      resource_info: [],
      activemenu: "-1",
      activeproj: [0,0],
      loading: true,
      loadimage: false,
      yadisk: '',
      newitem: {},
      yad: ['','',[],''],                              // 0-название,1-ссылка на загрузку,2-ссылки,3-ссылка на загрузку доп
      formid : 500,
      logs: [],
      modal: '',
      panel: [],
      usersearch: '',
      filteredInfo: null,
      sortedInfo: null,
      app: {},
      yaload: [],
      tags: [],
      popovervis: false,
      moreload: true,
      filestype: true,
      leftmenu: 0,
      returnid: 0,
      sitetempdate: [],
      sitepaid: [],
      reviews: [],
      news_mz: [],
      search: '',
      /* Deals Start */
      containerHeight: 0,
      isCombineEnabled: false,
      withScrollableColumns: true,
      deals_color: '#D9E3F0',
      deals_mode: 0,
      deals_list: [],
      deals_id: 0,
      deals_info: {},
      deals_users: [],
      deals_users_list: [],
      deals_currency: [],
      deals_transactions: [],
      deals_status: [],
      deals_status_transactions: [],
      deals_transaction: {},
      contacts_list: [],
      deals_transactions_completed: [],
      contact_info: {},
      deals_timer_enabled: true,
      deals_timer_status: false,
      deals_timer_visible: false
      /* Deals End */
    };
    this.onChange 	    = this.onChange.bind(this);
    this.yauploadserv		= this.yauploadserv.bind(this);
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(window.location.pathname);
  }

  //* Вызов сразу после рендера
  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    let app = this.state.app;
    if (document.domain === 'localhost') {
      app['url'] = 'https://adm.rus2.ru';
    } else {
      app['url'] = `https://${document.domain}`;
    }
    await this.setState( { app:app } );
    if (this.getCookie('token') !== undefined) {
      await this.login(1,1,this.getCookie('token'));
    } else {
      await this.setState( { auth:1 } );
    }
    switch (window.location.pathname) {
      case '/task':
        await this.menugo('3');
        break;
      case '/files':
        await this.menugo('2');
        break;
      case '/shop':
        await this.menugo('1');
        break;
      case '/clients':
        await this.menugo('4');
        break;
      case '/calc':
        await this.menugo('5');
        break;
      case '/domains':
        await this.menugo('6');
        break;
      case '/authors':
        await this.menugo('7');
        break;
      case '/sites':
        await this.menugo('8');
        break;
      case '/reviews':
        await this.menugo('9');
        break;
      case '/funnel':
        await this.menugo('10');
        break;
      case '/settings':
        await this.menugo('-2');
        break;
      default: break;
    }
  }

  updateDimensions = () => {
    //this.setState({ leftmenu:0 })
  };

  //* Текущее время 
  totime() {
    let date = new Date();
    let d1 = (date.getDate()<10?'0':'') + date.getDate();
    let d2 = date.getMonth()+1;
    d2 = (d2<10?'0':'') + d2;
    let d3 = date.getFullYear();
    let d4 = (date.getHours()<10?'0':'') + date.getHours();
    let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
    return `${d1}.${d2}.${d3} - ${d4}-${d5}`;
  }

  //* Загрузка файлов на Яндекс Диск
  async yauploadserv(event){
    this.setState( { loadimage: true } );
    notif('success','Загрузка файла...','');
    var blob = event.target.files[0].slice(0, event.target.files[0].size, event.target.files[0].type); 
    
    var yaload = this.state.yaload;
    await yaload.push(event.target.files[0]['name']);
    await this.setState( { yaload:yaload } );
    var file = new File([blob], this.totime()+' - '+event.target.files[0]['name'], {type: event.target.files[0].type});
    var path = `/uploads/lk/clients/service_prev/${file['name']}`;
    var fdata = {};
    await fetch('https://cloud-api.yandex.net/v1/disk/resources/upload?path='+encodeURI(path)+'&overwrite=true', {
      method: 'GET',
      mode: 'cors',
      headers: {
        authorization: `OAuth ${this.state.yadisk}`,
      },
    })
    .then(response => response.json())
    .then(async json => {
      var data = json;
      fdata = data;
      if(data.error) {
        notif('error',data.message,'');
      } else {

      }
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });

    if (!fdata.error) {
      //* Загрузка
      var formData = new FormData();
      formData.append('file', file);
      const loadfile = await fetch(fdata.href, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/binary'
        },
        body: file
      });
      const reader = loadfile.body.getReader();
      while (true) {
          const result = await reader.read();
          if (result.done) {
            let yaload = this.state.yaload;
            let indexya = yaload.indexOf(event.target.files[0]['name']);
            if (indexya > -1) {
              yaload.splice(indexya, 1);
            }

            //* Запись ссылок
            var proj4 = this.state.proj4;
            proj4[5][0] = path;           
            await this.setState( { proj4:proj4 } );
            break;
          }
      }
    }
    this.setState( { loadimage: false } );
  }

  //* POST запрос
  postRequest(url, data) {
    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
    .then(response => response.json())
  }

  //* Вход
  async login (l,p,t) {
    await this.setState( { auth: 0 } );
    let user = { login: l, pass: p, method: 'auth' };
    if (t) {
      user = { token: t, method: 'auth' };
    }
    
    await this.postRequest(`${this.state.app.url}/api_v2/`, user)
    .then(async json => {
      var data = json;
      if(data.code) {
        let profile 			= ( data.profile ) ? data.profile : {};
        let proj 			    = ( data.proj ) ? data.proj : {};
        let logs          = ( data.logs ) ? data.logs : [];
        let app          = ( data.app ) ? data.app : {};
        await this.setState( {
          profile: profile,
          proj: proj,
          logs: logs,
          app: app,
        } );

        //* Доступ к ЯД
        if (data.yadisk) {
          await this.setState( {
            yadisk: data.yadisk
          } );
        }
        if (!t) {
          this.setCookie('token', data.profile.token);
          notif('success','Вы успешно вошли!','');
          window.location.href = '/';
        }
        await this.setState( {
          auth:2,
          loading: false
        } );
      }
      if(data.error) {
        await this.setState( { auth:1, error: data.error } );
        notif('error',data.error[0],data.error[1]);
      }
    })
    .catch( async err => {
      await this.setState( { auth:1, error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
  }

  //* Текст поиска
  onChange (e) { 
    this.setState({ usersearch:e.target.value.replace(/\s+/g,' ') }); 
    this.setState({ search:e.target.value.replace(/\s+/g,' ') }); 
  }

  //* Получение информации
  async api(v,s) {
    var out = [];
    if (s) {
      await this.setState( { loading: true, moreload:true, modal:'' } );
    } else {
      await this.setState({ moreload:true });
    }
    
    v['token'] = this.getCookie('token');
    await this.postRequest(`${this.state.app.url}/api_v2/`, v)
    .then(async json => {
      var data = json;
      if(data.code) {
        //* Профиль
        let profile 			= ( data.profile ) ? data.profile : {};
        let proj 			    = ( data.proj ) ? data.proj : {};
        let logs          = ( data.logs ) ? data.logs : [];
        let app          = ( data.app ) ? data.app : [];
        await this.setState( {
          profile: profile,
          proj: proj,
          logs: logs,
          app: app,
        } );

        //* Доступ к ЯД
        if (data.yadisk) {
          await this.setState( {
            yadisk: data.yadisk
          } );
        }

        var yad = this.state.yad;
        yad[0] = '';
        yad[1] = '';
        yad[2] = [];
        yad[3] = '';
        await this.setState( { yad:yad, yaload:[] } );

        //* Методы
        switch(v['method']) {
          case 'reviews_list':
            var reviews    = ( data.list ) ? data.list : [];
            await this.setState( {
              reviews: reviews
            } );
            break;
          case 'user_add':
            var users 		= ( data.userlist ) ? data.userlist : [];
            await this.setState( { users:users } );
            notif('success','Пользователь успешно добавлен!','');
            break;
          case 'user_upd':
            users 		= ( data.userlist ) ? data.userlist : [];
            await this.setState( { users:users } );
            notif('success','Пользователь успешно обновлен!','');
            break;
          case 'user_del':
            users 		= ( data.userlist ) ? data.userlist : [];
            await this.setState( { users:users } );
            notif('success','Пользователь заблокирован!','');
            break;
          case 'users_tg':
            users 		= ( data.userlist ) ? data.userlist : [];
            await this.setState( { users:users } );
            notif('success','Ключ обновлен!','');
            break;
          case 'users_tgmy':
            users 		= ( data.userlist ) ? data.userlist : [];
            await this.setState( { users:users } );
            notif('success','Ключ обновлен!','');
            break;
          case 'user_pass':
            users 		= ( data.userlist ) ? data.userlist : [];
            await this.setState( { users:users } );
            notif('success','Пароль обновлён!','');
            break;
          case 'users_data':
            users 		= ( data.userlist ) ? data.userlist : [];
            await this.setState( { users:users } );
            break;
          case 'group_list':
            var groups 		= ( data.list ) ? data.list : [];
            await this.setState( { groups:groups } );
            break;
          case 'group_add':
            groups 		= ( data.list ) ? data.list : [];
            await this.setState( { groups:groups } );
            notif('success','Группа добавлена!','');
            break;
          case 'group_upd':
            groups 		= ( data.list ) ? data.list : [];
            await this.setState( { groups:groups } );
            notif('success','Группа обновлена!','');
            break;
          case 'group_del':
            groups 		= ( data.list ) ? data.list : [];
            await this.setState( { groups:groups } );
            notif('success','Группа удалена!','');
            break;
          case 'res_info':
            var resource_info 		= ( data.get ) ? data.get : [];
            await this.setState( {
              resource_info:resource_info
            } );
            await this.panel('res_info');
            break;
          case 'res_new':
            var proj1 = this.state.proj1;
            var reslist 		= ( data.list ) ? data.list : [];
            var returnid = ( data.returnid ) ? data.returnid : 0;
            proj1[5] = reslist;
            await this.setState( {
              proj1: proj1
            } );
            notif('success','Страница файла создана!','');
            await this.api({
              'method':'res_info',
              'resid':returnid
            },false);
            await this.panel('res_info');
            break;
          case 'res_newupd':
            resource_info 		= ( data.get ) ? data.get : [];
            reslist 		= ( data.list ) ? data.list : [];
            proj1[5] = reslist;
            await this.setState( {
              proj1: proj1, resource_info:resource_info
            } );
            notif('success','Информация о файле обновлена!','');
            break;
          case 'res_updimg':
            proj1 = this.state.proj1;
            reslist 		= ( data.list ) ? data.list : [];
            resource_info 		= ( data.get ) ? data.get : [];
            proj1[5] = reslist;
            await this.setState( {
              proj1: proj1, resource_info:resource_info
            } );
            notif('success','Файл успешно загружен!','');
            break;
          case 'res_reload':
            proj1 = this.state.proj1;
            reslist 		= ( data.list ) ? data.list : [];
            resource_info 		= ( data.get ) ? data.get : [];
            proj1[5] = reslist;
            await this.setState( {
              proj1: proj1, resource_info:resource_info
            } );
            notif('success','Изображение перезагружено!','');
            break;
          case 'res_data':
            proj1 = this.state.proj1;
            var rescat 		= ( data.data.cat ) ? data.data.cat : [];
            var owners 		= ( data.data.owners ) ? data.data.owners : [];
            var tags 		= ( data.data.tags ) ? data.data.tags : [];
            var owner 		= ( data.data.owner ) ? data.data.owner : '1';
            reslist 		= ( data.list ) ? data.list : [];
            proj1[0] = rescat;
            proj1[5] = reslist;
            proj1[7] = owners;
            proj1[8] = tags;
            proj1[9] = owner;
            await this.setState( {
              proj1: proj1
            } );
            break;
          case 'res_del':
            proj1 = this.state.proj1;
            reslist 		= ( data.list ) ? data.list : [];
            proj1[5] = reslist;
            await this.setState( {
              proj1: proj1
            } );
            notif('success','Ресурс удалён!','');
            break;
          case 'res_get':
            var modal 		= ( data.modal ) ? data.modal : [];
            //this.modallink(modal);
            window.open(modal.url, "_blank");
            break;
          case 'shop_data':
            var proj2 = this.state.proj2;
            rescat 		= ( data.data.cat ) ? data.data.cat : [];
            reslist 		= ( data.list ) ? data.list : [];
            var reslist2 		= ( data.list2 ) ? data.list2 : [];
            proj2[1] = rescat;
            proj2[2] = reslist;
            proj2[3] = reslist2;
            await this.setState( {
              proj2: proj2
            } );
            break;
          case 'shop_add':
            proj2 = this.state.proj2;
            reslist 		= ( data.list ) ? data.list : [];
            reslist2 		= ( data.list2 ) ? data.list2 : [];
            proj2[2] = reslist;
            proj2[3] = reslist2;
            await this.setState( {
              proj2: proj2
            } );
            notif('success','Товар успешно опубликован!','');
            break;
          case 'shop_del':
            proj2 = this.state.proj2;
            reslist 		= ( data.list ) ? data.list : [];
            reslist2 		= ( data.list2 ) ? data.list2 : [];
            proj2[2] = reslist;
            proj2[3] = reslist2;
            await this.setState( {
              proj2: proj2
            } );
            notif('success','Товар удалён!','');
            break;
          case 'shop_upd':
            proj2 = this.state.proj2;
            reslist 		= ( data.list ) ? data.list : [];
            reslist2 		= ( data.list2 ) ? data.list2 : [];
            proj2[2] = reslist;
            proj2[3] = reslist2;
            await this.setState( {
              proj2: proj2
            } );
            notif('success','Товар обновлён!','');
            break;
          case 'cli_data':
            var proj4 = this.state.proj4;
            var clicat    = ( data.cat ) ? data.cat : [];
            users 		= ( data.userlist ) ? data.userlist : [];
            proj4[1] = clicat;
            proj4[3] = users;
            await this.setState( {
              proj4: proj4
            } );
            break;
          case 'clis_add':
            proj4 = this.state.proj4;
            clicat    = ( data.cat ) ? data.cat : [];
            var clilist    = ( data.list ) ? data.list : [];
            proj4[1] = clicat;
            proj4[2] = clilist;
            proj4[0] = '4';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Услуга успешно добавлена!','');
            break;
          case 'clis_del':
              proj4 = this.state.proj4;
              clicat    = ( data.cat ) ? data.cat : [];
              clilist    = ( data.list ) ? data.list : [];
              proj4[1] = clicat;
              proj4[2] = clilist;
              proj4[0] = '4';
              await this.setState( {
                proj4: proj4
              } );
              notif('success','Услуга успешно удалена!','');
              break;
          case 'clis_upd':
              proj4 = this.state.proj4;
              clicat    = ( data.cat ) ? data.cat : [];
              clilist    = ( data.list ) ? data.list : [];
              proj4[1] = clicat;
              proj4[2] = clilist;
              proj4[0] = '4';
              await this.setState( {
                proj4: proj4
              } );
              notif('success','Услуга успешно обновлена!','');
              break;
          case 'clis_list':
            proj4 = this.state.proj4;
            clilist    = ( data.list ) ? data.list : [];
            proj4[2] = clilist;
            await this.setState( {
              proj4: proj4
            } );
            break;
          case 'site_add':
            proj4 = this.state.proj4;
            var sitelist    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[6] = sitelist;
            proj4[0] = '2';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Сайт успешно добавлен!','');
            break;
          case 'site_list':
            proj4 = this.state.proj4;
            sitelist    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[6] = sitelist;
            await this.setState( {
              proj4: proj4
            } );
            break;
          case 'site_del':
            proj4 = this.state.proj4;
            sitelist    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[6] = sitelist;
            proj4[0] = '2';
            proj4[7] = {};
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Сайт успешно удалён!','');
            break;
          case 'sitedom':
            proj4 = this.state.proj4;
            sitelist    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = sitelist;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Дата окончания регистрации домена успешно изменена!','');
            break;
          case 'siteedit':
            proj4 = this.state.proj4;
            sitelist    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = sitelist;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Данные успешно обновлены!','');
            break;
          case 'siteserv_add':
            proj4 = this.state.proj4;
            sitelist    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = sitelist;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Услуга успешно добавлена!','');
            break;
          case 'site_serv_add':
            notif('success','Услуга успешно добавлена!','');
            break;
          case 'siteserv_del':
            proj4 = this.state.proj4;
            sitelist    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = sitelist;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Услуга успешно удалена!','');
            break;
          case 'site_serv_del':
            notif('success','Услуга успешно удалена!','');
            break;
          case 'site_get':
            proj4 = this.state.proj4;
            var get    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = get;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            break;
          case 'site_get_date':
            var get    = ( data.list ) ? data.list : [];
            await this.setState( {
              sitetempdate: get
            } );
            break;
          case 'site_paid':
            var get    = ( data.list ) ? data.list : [];
            await this.setState( {
              sitepaid: get
            } );
            break;
          case 'site_value_date':
            notif('success','Статус даты обновлён!','');
            break;
          case 'site_service_date':
            notif('success','Дата услуги обновлена!','');
            break;
          case 'siteseo_add':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = get;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','SEO запрос успешно добавлен!','');
            break;
          case 'seo_del':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = get;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','SEO запрос успешно удалён!','');
            break;
          case 'sitehis_add':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = get;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','История работ успешно пополнена!','');
            break;
          case 'his_del':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = get;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','История работ уменьшилась!','');
            break;
          case 'siterep_add':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = get;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Отчёт успешно добавлен!','');
            break;
          case 'rep_del':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            clilist    = ( data.clist ) ? data.clist : [];
            proj4[2] = clilist;
            proj4[7] = get;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Отчёт успешно удалён!','');
            break;
          case 'rep_get':
            Modal.success({content: (<><p>Ссылка получена!</p><Link href={data.url} target="_blank" rel="noopener noreferrer">Скачать</Link></>),});
            break;
          case 'clients_add':
            proj4 = this.state.proj4;
            users 		= ( data.userlist ) ? data.userlist : [];
            proj4[3] = users;
            await this.setState( { 
              users:users
            } );
            notif('success','Клиент успешно добавлен!','');
            break;
          case 'sitecopy_add':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            proj4[7] = get;
            proj4[0] = '5';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Резервная копия успешно добавлена!','');
            break;
          case 'sitecopy_del':
              proj4 = this.state.proj4;
              get    = ( data.list ) ? data.list : [];
              proj4[7] = get;
              proj4[0] = '5';
              await this.setState( {
                proj4: proj4
              } );
              notif('success','Резервная копия успешно удалена!','');
              break;
          case 'sitecopy_get':
            Modal.success({content: (<><p>Ссылка получена!</p><Link href={data.url} target="_blank" rel="noopener noreferrer">Скачать</Link></>),});
            break;
          case 'clients_del':
            proj4 = this.state.proj4;
            users 		= ( data.userlist ) ? data.userlist : [];
            proj4[3] = users;
            proj4[0] = '1';
            await this.setState( { 
              users:users
            } );
            notif('success','Клиент заблокирован!','');
            break;
          case 'clients_upd':
            proj4 = this.state.proj4;
            users 		= ( data.userlist ) ? data.userlist : [];
            proj4[3] = users;
            await this.setState( { 
              users:users 
            } );
            notif('success','Клиент обновлён!','');
            break;
          case 'clients_upd2':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            var sites    = ( data.sites ) ? data.sites : [];
            proj4[8] = get;
            proj4[9] = sites;
            proj4[0] = '6';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Клиент обновлён!','');
            break;
          case 'clients_get':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            sites    = ( data.sites ) ? data.sites : [];
            proj4[8] = get;
            proj4[9] = sites;
            proj4[0] = '6';
            await this.setState( {
              proj4: proj4
            } );
            break;
          case 'clients_sitedef':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            sites    = ( data.sites ) ? data.sites : [];
            proj4[8] = get;
            proj4[9] = sites;
            proj4[0] = '6';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Значение сайта по умолчанию успешно изменено!','');
            break;
          case 'clients_addsite':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            sites    = ( data.sites ) ? data.sites : [];
            proj4[8] = get;
            proj4[9] = sites;
            proj4[0] = '6';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Клиенту присвоен сайт!','');
            break;
          case 'clidoc_add':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            sites    = ( data.sites ) ? data.sites : [];
            proj4[8] = get;
            proj4[9] = sites;
            proj4[0] = '6';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Клиенту добавлен документ!','');
            break;
          case 'clidoc_del':
              proj4 = this.state.proj4;
              get    = ( data.list ) ? data.list : [];
              sites    = ( data.sites ) ? data.sites : [];
              proj4[8] = get;
              proj4[9] = sites;
              proj4[0] = '6';
              await this.setState( {
                proj4: proj4
              } );
              notif('success','У клиента удалён документ!','');
              break;
          case 'clidoc_upd':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            sites    = ( data.sites ) ? data.sites : [];
            proj4[8] = get;
            proj4[9] = sites;
            proj4[0] = '6';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','Документ клиента обновлён!','');
            break;
          case 'clidoc_get':
            Modal.success({content: (<><p>Ссылка получена!</p><Link href={`${this.state.app.url}/docs/${data.url}`} download target="_blank" rel="noopener noreferrer">Скачать</Link></>),});
            break;
          case 'clidoc_email':
            notif('success','Документ отправлен!','');
            break;
          case 'clients_delsite':
            proj4 = this.state.proj4;
            get    = ( data.list ) ? data.list : [];
            sites    = ( data.sites ) ? data.sites : [];
            proj4[8] = get;
            proj4[9] = sites;
            proj4[0] = '6';
            await this.setState( {
              proj4: proj4
            } );
            notif('success','У клиента больше нет данного сайта!','');
            break;
          case 'getcalc':
            var proj5 = this.state.proj5;
            get    = ( data.list ) ? data.list : [];
            proj5[2] = get;
            await this.setState( {
              proj5: proj5
            } );
            break;
          case 'domains_list':
            var proj6 = this.state.proj6;
            get    = ( data.list ) ? data.list : [];
            proj6[1] = get;
            await this.setState( {
              proj6: proj6
            } );
            break;
          case 'domains_add':
            proj6 = this.state.proj6;
            get    = ( data.list ) ? data.list : [];
            proj6[1] = get;
            await this.setState( {
              proj6: proj6
            } );
            notif('success','Домен добавлен!','');
            break;
          case 'domains_del':
            proj6 = this.state.proj6;
            get    = ( data.list ) ? data.list : [];
            proj6[1] = get;
            await this.setState( {
              proj6: proj6
            } );
            notif('success','Домен удалён!','');
            break;
          case 'domains_upd':
            proj6 = this.state.proj6;
            get    = ( data.list ) ? data.list : [];
            proj6[1] = get;
            await this.setState( {
              proj6: proj6
            } );
            notif('success','Домен обновлён!','');
            break;
          case 'tasks_list':
            var proj3 = this.state.proj3;
            get    = ( data.list ) ? data.list : [];
            var groups    = ( data.groups ) ? data.groups : [];
            users    = ( data.users ) ? data.users : [];
            proj3[1] = get;
            proj3[2] = users;
            proj3[3] = [];
            await this.setState( {
              proj3: proj3,
              groups: groups
            } );
            break;
          case 'tasks_get':
            var proj3 = this.state.proj3;
            get    = ( data.get ) ? data.get : [];
            users    = ( data.users ) ? data.users : [];
            groups    = ( data.groups ) ? data.groups : [];
            proj3[3] = get;
            await this.setState( {
              proj3: proj3,
              groups: groups
            } );
            break;
          case 'tasks_add':
            proj3 = this.state.proj3;
            get    = ( data.list ) ? data.list : [];
            users    = ( data.users ) ? data.users : [];
            groups    = ( data.groups ) ? data.groups : [];
            proj3[1] = get;
            proj3[2] = users;
            await this.setState( {
              proj3: proj3,
              groups: groups
            } );
            notif('success','Задача добавлена!','');
            break;
          case 'tasks_del':
            proj3 = this.state.proj3;
            get    = ( data.list ) ? data.list : [];
            users    = ( data.users ) ? data.users : [];
            groups    = ( data.groups ) ? data.groups : [];
            proj3[1] = get;
            proj3[2] = users;
            await this.setState( {
              proj3: proj3,
              groups: groups
            } );
            notif('success','Задача удалена!','');
            break;
          case 'tasks_col':
            proj3 = this.state.proj3;
            get    = ( data.list ) ? data.list : [];
            users    = ( data.users ) ? data.users : [];
            groups    = ( data.groups ) ? data.groups : [];
            proj3[1] = get;
            proj3[2] = users;
            await this.setState( {
              proj3: proj3,
              groups: groups
            } );
            break;
          case 'tasks_push':
            proj3 = this.state.proj3;
            get    = ( data.list ) ? data.list : [];
            users    = ( data.users ) ? data.users : [];
            groups    = ( data.groups ) ? data.groups : [];
            proj3[1] = get;
            proj3[2] = users;
            await this.setState( {
              proj3: proj3,
              groups: groups
            } );
            break;
          case 'tasks_upd':
            proj3 = this.state.proj3;
            get    = ( data.list ) ? data.list : [];
            users    = ( data.users ) ? data.users : [];
            groups    = ( data.groups ) ? data.groups : [];
            proj3[1] = get;
            proj3[2] = users;
            await this.setState( {
              proj3: proj3,
              groups: groups
            } );
            notif('success','Задача обновлена!','');
            break;
          case 'tasks_comp':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups    = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3,
                groups: groups
              } );
              notif('success','Статус задачи обновлен!','');
              break;
          case 'tasks_item':
            proj3 = this.state.proj3;
            get    = ( data.list ) ? data.list : [];
            users    = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            proj3[1] = get;
            proj3[2] = users;
            await this.setState( {
              proj3: proj3, 
              groups: groups
            } );
            notif('success','Статус пункта обновлен!','');
            break;
          case 'tasks_work':
            proj3 = this.state.proj3;
            get    = ( data.list ) ? data.list : [];
            users    = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            proj3[1] = get;
            proj3[2] = users;
            await this.setState( {
              proj3: proj3,
              groups: groups
            } );
            notif('success','Статус задачи обновлен!','');
            break;
          /* Deals Start */
          case 'deals_list':
            var deals  = ( data.list ) ? data.list : [];
            await this.setState( {
              deals_list: deals
            } );
            if (this.state.deals_timer_enabled) {
              if (!this.state.deals_timer_status) {
                this.setState( { deals_timer_status:true } );
                setInterval(() => 
                  {
                    if (this.state.modal === '' || this.state.panel === []) {
                      
                    } else {
                      this.setState({ deals_timer_visible:true });
                      //* Список контактов
                      this.api({ 'method':'deals_contacts_list' }, false);
                      //* Список пользователей
                      // this.api({ 'method':'deals_users' }, false);
                      //* Валюты
                      // this.api({ 'method':'deals_currency' }, false);
                      
                      if ('id' in this.state.deals_info) {
                        //* Информация о воронке
                        this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
                        //* Список пользователей в воронке
                        this.api({ 'method':'deals_users_list','deal_id':this.state.deals_info.id }, false);
                      } else {
                        //* Список воронок
                        this.api({ 'method':'deals_list'}, false);
                      }

                      if (this.state.deals_transactions_completed.length) {
                        //* Список завершенных сделок
                        this.api({'method':'deals_transactions_completed','deal_id':this.state.deals_info.id}, false);
                      }

                      if ('id' in this.state.contact_info) {
                        //* Информация о контакте
                        this.api({ 'method':'deals_contact_get', 'id':this.state.contact_info.id }, false);
                      }

                      if ('id' in this.state.deals_transaction) {
                        //* Информация о сделке
                        this.api({ 'method':'deals_transaction_get', 'id':this.state.deals_transaction.id, 'deal_id':this.state.deals_transaction.deal_id }, false);
                      }
                      setTimeout(() => 
                        this.setState({ deals_timer_visible:false })
                      , 2000);
                    }
                  }
                , 15000);

              }
            }
            break;
          case 'deals_add':
            var deals_id  = ( data.info ) ? data.info : 0;
            this.modal('');
            this.setState({ deals_mode: 1 })
            await this.api({ 'method':'deals_get', 'id':deals_id }, false);
            
            notif('success','Воронка создана!','');
            break;
          case 'deals_get':
            var deal = ( data.info ) ? data.info : {};
            var deals_info  = ( deal.info ) ? deal.info : {};
            var deals_status  = ( deal.status ) ? deal.status : [];
            var deals_transactions  = ( deal.transactions ) ? deal.transactions : [];
            
            var deals_status_transactions = deals_status.reduce(
              (previous, status) => ({
                ...previous,
                [status.id]: getByStatus(status, deals_transactions)
              }),
              {}
            );
            await this.setState( {
              deals_info: deals_info,
              deals_color: deals_info.color,
              deals_status: deals_status,
              deals_status_transactions:deals_status_transactions
            } );

            break;
          case 'deals_users':
            var deals_users  = ( data.list ) ? data.list : [];
            await this.setState( {
              deals_users: deals_users
            } );
            break;
          case 'deals_user_add':
            await this.api({ 'method':'deals_users_list','deal_id':this.state.deals_info.id }, false);
            notif('success','Пользователь добавлен!','');
            break;
          case 'deals_user_del':
            await this.api({ 'method':'deals_users_list','deal_id':this.state.deals_info.id }, false);
            notif('success','Пользователь удален!','');
            break;
          case 'deals_users_list':
            var deals_users_list  = ( data.list ) ? data.list : [];
            await this.setState( {
              deals_users_list: deals_users_list
            } );
            break;
          case 'deals_stats':
            var deals_stats  = ( data.list ) ? data.list : {};
            await this.setState( {
              deals_stats: deals_stats
            } );
            break;
          case 'deals_currency':
            var deals_currency  = ( data.list ) ? data.list : [];
            await this.setState( {
              deals_currency: deals_currency
            } );
            break;
          case 'deals_settings':
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            notif('success','Настройки сохранены!','');
            break;
          case 'deals_contacts_list':
            var contacts_list  = ( data.list ) ? data.list : [];
            await this.setState( {
              contacts_list: contacts_list
            } );
            break;
          case 'deals_contact_get':
            var contact_info  = ( data.info ) ? data.info : {};
            await this.setState( {
              contact_info: contact_info
            } );
            break;
          case 'deals_contact_add':
            this.panel('contactAdd');
            await this.api({'method':'deals_contacts_list'}, false);
            notif('success','Контакт добавлен!','');
            break;
          case 'deals_contact_upd':
            await this.api({'method':'deals_contacts_list'}, false);
            await this.api({ 'method':'deals_contact_get', 'id':this.state.contact_info.id }, false);
            notif('success','Контакт обновлён!','');
            break;
          case 'deals_contact_del':
            await this.api({'method':'deals_contacts_list'}, false);
            notif('success','Контакт удален!','');
            break;
          case 'form_contact_comment_add':
            await this.api({ 'method':'deals_contact_get', 'id':this.state.contact_info.id }, false);
            notif('success','Комментарий отправлен!','');
            break;
          case 'deals_contact_transaction_del':
            await this.api({'method':'deals_contacts_list'}, false);
            await this.api({ 'method':'deals_contact_get', 'id':this.state.contact_info.id }, false);
            notif('success','Сделка отвязана!','');
            break;
          case 'deals_transaction_contact_join':
            await this.api({ 'method':'deals_transaction_get', 'id':this.state.deals_transaction.id, 'deal_id':this.state.deals_transaction.deal_id }, false);
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            notif('success','Контакт привязан!','');
            break;
          case 'deals_transaction_contact_del':
            await this.api({ 'method':'deals_transaction_get', 'id':this.state.deals_transaction.id, 'deal_id':this.state.deals_transaction.deal_id }, false);
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            notif('success','Контакт отвязан!','');
            break;
          case 'deals_transaction_contact_add':
            await this.api({ 'method':'deals_transaction_get', 'id':this.state.deals_transaction.id, 'deal_id':this.state.deals_transaction.deal_id }, false);
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            notif('success','Контакт привязан!','');
            break;
          case 'form_transaction_comment_add':
            await this.api({ 'method':'deals_transaction_get', 'id':this.state.deals_transaction.id, 'deal_id':this.state.deals_transaction.deal_id }, false);
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            notif('success','Комментарий отправлен!','');
            break;
          case 'deals_transactions_completed':
            var deals_transactions_completed  = ( data.list ) ? data.list : [];
            await this.setState( {
              deals_transactions_completed: deals_transactions_completed
            } );
            break;
          case 'deals_transaction_get':
            var transaction_info  = ( data.info ) ? data.info : {};
            await this.setState( {
              deals_transaction: transaction_info
            } );
            break;
          case 'deals_transaction_add':
            this.modal('');
            this.setState({ deals_mode: 1 })
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Сделка создана!','');
            break;
          case 'deals_transaction_upd':
            await this.api({ 'method':'deals_transaction_get', 'id':this.state.deals_transaction.id, 'deal_id':this.state.deals_transaction.deal_id }, false);
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Сделка обновлена!','');
            break;
          case 'deals_transaction_del':
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Сделка удалена!','');
            break;
          case 'deals_transaction_comp':
            await this.api({'method':'deals_transactions_completed','deal_id':this.state.deals_info.id}, false);
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Сделка обновлена!','');
            break;
          case 'deals_status_add':
            this.modal('');
            this.setState({ deals_mode: 1 })
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Статус создан!','');
            break;
          case 'deals_status_edit':
            this.modal('');
            this.setState({ deals_mode: 1 })
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Статус обновлён!','');
            break;
          case 'deals_status_del':
            this.modal('');
            this.setState({ deals_mode: 1 })
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Статус удалён!','');
            break;
          case 'deals_status_sorts':
            this.modal('');
            this.setState({ deals_mode: 1 })
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Статус перемещён!','');
            break;
          case 'deals_transaction_status_sort':
            this.modal('');
            this.setState({ deals_mode: 1 })
            //await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Сделка перемещена!','');
            break;
          case 'deals_transaction_sort':
            this.modal('');
            this.setState({ deals_mode: 1 })
            //await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            
            notif('success','Порядок изменён!','');
            break;
          case 'deals_transaction_sorts':
            this.modal('');
            this.setState({ deals_mode: 1 })
            await this.api({ 'method':'deals_get', 'id':this.state.deals_info.id }, false);
            break;
          /* Deals End */
          default: break;
        }
      }
      if(data.error) {
        await this.setState( { error: data.error } );
        notif('error',data.error[0],data.error[1]);
        if (data.error[0] === 'Неверный логин или пароль') {
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
          window.location.href = '/login';
        }
      }
      out = data;
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
    if (s) {
      await this.setState( { loading: false, moreload: false } );
    } else {
      await this.setState({ moreload:false });
    }
    return out;
  }

  //* Получение нужного куки
  getCookie(name) {
    let matches = document.cookie.match(new RegExp( "(?:^|; )" + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + "=([^;]*)" ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  //* Линк на пункт меню
  menulink = (e) => { let id = e.currentTarget.dataset.id; this.menugo(id); }
  
  //* Обнуление Tab разделов
  async deltabs() {
    let proj1 = this.state.proj1;
    let proj2 = this.state.proj2;
    let proj3 = this.state.proj3;
    let proj4 = this.state.proj4;
    let setting = this.state.setting;

    proj1[3] = '1'; proj2[0] = '1';  proj3[0] = '1'; proj4[0] = '1'; setting[0] = '1'; let tags = [];
    await this.setState( { proj1:proj1,proj2:proj2,proj3:proj3,proj4:proj4,setting:setting,tags:tags } );
  }

  //* Переход на другой раздел
	async menugo(a) {
    await this.setState( { loading: true, leftmenu:0 } );
    await this.deltabs();
    if (a < 1) {
      await this.setState( { activemenu:a } );
      switch(a) {
        case '0':
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
          notif('success','Вы успешно вышли!','Подождите пару секунд/');
          await window.location.reload();
          break;
        case '-1':
          await this.api({ 'method':'auth' },true);
          break;
        case '-2':
          await this.api({ 'method':'users_data' },true);
          break;
        default:  break;
      }
      await this.setState( { loading: false } );
    } else {
      switch(a) {
        //* Магазин
        case '1':
          await this.api({ 'method':'shop_data' },true);
          break;
        //* Ресурсы
        case '2':
          await this.api({ 'method':'res_data' },true);
          break;
        //* Задачи
        case '3':
          await this.api({ 'method':'tasks_list' },true);
          break;
        //* Клиенты
        case '4':
          await this.api({ 'method':'cli_data' },true);
          break;
        //* Калькулятор
        case '5':
          await this.api({ 'method':'getcalc','theme':'instagram'},true);
          break;
        //* Список доменов
        case '6':
          await this.api({ 'method':'domains_list' },true);
          break;
        //* Список опросов
        case '9':
          await this.api({ 'method':'reviews_list' },true);
          break;
        //* Воронки
        case '10':
          await this.api({ 'method':'deals_list' },true);
          break;
        //* Авторское право
        case '7':
          //!
          break;
        default:  break;
      }
      let projIn = this.state.proj.findIndex(proj => proj.id === a);  //* Индекс проекта в массиве
      let projId = this.state.proj.find(proj => proj.id === a).id;    //* ID проекта

      await this.setState( { activemenu: a, activeproj:[projIn,projId], loading: false } );
    }
  }

  //* Добавление куки
  setCookie(name, value, options = {}) {
    options = { path: '/', ...options };
    if (options.expires instanceof Date) { options.expires = options.expires.toUTCString(); }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) { updatedCookie += "=" + optionValue; }
    }
    document.cookie = updatedCookie;
  }

  //* Сортировка
  htc = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  //* Модальное окно после запроса
  async modallink(resp) {
    if ('title' in resp && 'url' in resp) {
      await Modal.info({
        title: resp.title,
        className: "center_footer",
        content: (
          <div className="fcenter">
            <Link className="pulse" href={resp.url} target="_blank" rel="noopener noreferrer"><DownloadOutlined style={{fontSize:30}} /></Link>
          </div>
        ),
        maskClosable: true,
        okType: 'dashed',
        okText: 'Закрыть',
        okButtonProps: {shape:'round'},
        onOk() {},
      });
    }
  }

  //* Удаление куки
  delCookie (name) { this.setCookie(name, "", { 'max-age': -1 }) };

  //* Случайный цвет
  randcolor() {
    var arr = ['#cc0029', '#E14015', '#f56a00', '#FA9500', '#ffbf00', '#9EB51E', '#3caa3c', '#1EA675', '#00a2ae', '#42aaff', '#2E82E8', '#1959d1', '#7265e6'];
    var rand = Math.floor(Math.random() * arr.length);
    return arr[rand];
  }

  //* Смена вкладки Tab ресурса
  async changetab(v) {
    switch (v) {
      case '1':
        await this.api({ 'method':'res_data','tags':this.state.tags.join() },true);
        break;
      default: break;
    }
    let proj1 = this.state.proj1;
    proj1[3] = v;
    var yad = this.state.yad;
    yad[0] = '';
    yad[1] = '';
    yad[2] = [];
    await this.setState( { proj1:proj1, yad:yad } );
  }

  //* Смена вкладки Tab магазина
  async changetab2(v) {
    switch (v) {
      case '1':
        await this.api({ 'method':'shop_data' },true);
        break;
      default: break;
    }
    let proj2 = this.state.proj2;
    proj2[0] = v;
    await this.setState( { proj2:proj2 } );
  }

  //* Смена вкладки Tab клиентов
  async changetab4(v) {
    switch (v) {
      case '1':
        await this.api({ 'method':'cli_data' },true);
        break;
      case '2':
        await this.api({ 'method':'cli_data' },true);
        await this.api({ 'method':'site_list' },true);
        break;
      case '4':
        await this.api({ 'method':'cli_data' },true);
        await this.api({ 'method':'clis_list' },true);
        break;
      default: break;
    }
    let proj4 = this.state.proj4;
    proj4[0] = v;
    proj4[5] = {};
    await this.setState( { proj4:proj4 } );
  }

  //* Смена вкладки Tab калькулятора
  async changetab5(v) {
    switch (v) {
      case '1':
        await this.api({ 'method':'getcalc','theme':'instagram'},true);
        break;
      default: break;
    }
    let proj5 = this.state.proj5;
    proj5[0] = v;
    proj5[1] = 0;
    await this.setState( { proj5:proj5 } );
  }

  //* Готовые иконки
  icons(n) {
    switch(n) {
      case '-1': return <HomeOutlined />;
      case '1': return <BarsOutlined />;
      case '-2': return <SettingOutlined />;
      case '0': return <ImportOutlined />;
      case 'task': return <FireOutlined />;
      case 'files': return <FolderOpenOutlined />;
      case 'shop': return <ShoppingCartOutlined />;
      case 'clients': return <TeamOutlined />;
      case 'calc' : return <CalculatorOutlined />;
      case 'domains' : return <DollarCircleOutlined />;
      case 'sites': return <GlobalOutlined />;
      case 'authors' : return <TrophyOutlined />;
      case 'reviews' : return <CommentOutlined />;
      case 'funnel' : return <FunnelPlotOutlined />;
      default: return <AppstoreOutlined />;
    }
  }

  //* Смена вкладки Tab настроек
  async changesett(v) {
    let sett = this.state.setting;
    sett[0] = v;
    switch (v) {
      case '1':
        await this.api({ 'method':'auth' },true);
        break;
      case '2':
        await this.api({ 'method':'users_data' },true);
        await this.api({ 'method':'group_list' },true);
        break;
      case '3':
        await this.api({ 'method':'group_list' },true);
        break;
      case '4':
        await this.api({ 'method':'users_log' },true);
        break;
      default: 
        break;
    }
    await this.setState( { setting:sett } );
  }

  //* Модальные окна
  async modal(t) {
    var proj4 = this.state.proj4;
    proj4[5] = [];
    this.setState( { modal:t, proj4:proj4 } );
  }

  //* Модальные окна
  async panel(t) {
    var panels = this.state.panel;
    if (panels.indexOf( t ) != -1) {
      var index = panels.indexOf(t);
      if (index > -1) panels.splice(index, 1);
    } else {
      await panels.push(t);
    }
    await this.setState( { panel:panels } );
  }

  decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

  //* Полная дата
  fulltime(date,time ) {
    if (time === undefined) {time = '00:00';}
    var ot = new Date(date);
    var nt = new Date('1970-01-01T' + time + ':00');
    ot.setHours(nt.getHours(),nt.getMinutes());
    return ot;
  }

  //* Дата
  lasttime(time,empty,type) {
    let newtime 	= new Date();
		let oldtime 	= new Date();
		let notiftime 	= new Date( time * 1000 );
		newtime.setMinutes( newtime.getMinutes() );
		oldtime.setMinutes( newtime.getMinutes() - 1440 );
		notiftime.setMinutes( notiftime.getMinutes());
		let newdate 	= Math.floor( newtime.getTime()/1000 );
		let notifdate 	= Math.floor( notiftime.getTime()/1000 );
		let diff 		= newdate-notifdate;
		let monthlist 	= { "00":"янв ", "01":"фев ", "02":"мар ", "03":"апр ", "04":"мая ", "05":"июн ", "06":"июл ", "07":"авг ", "08":"сен ", "09":"окт ", "10":"ноя ", "11":"дек " };
    let monthlistf 	= { "00":"Январь ", "01":"Февраль ", "02":"Март ", "03":"Апрель ", "04":"Май ", "05":"Июнь ", "06":"Июль ", "07":"Август ", "08":"Сентябрь ", "09":"Октябрь ", "10":"Ноябрь ", "11":"Декабрь " };
		let info 		= '';
		if ( diff < 60 ) 	{ info = Math.floor( diff ) + this.decnum( Math.floor( diff ),[ ' секунду ',' секунды ',' секунд ' ] ) + " назад "; }
		if ( diff > 60 ) 	{ info = Math.floor( diff/60 ) + this.decnum( Math.floor( diff/60 ),[ ' минуту ',' минуты ',' минут '] ) + " назад "; }
		if ( diff > 1800 ) 	{ info = "полчаса назад "; }
		if ( diff > 3600 ) 	{ info = "час назад "; }
		if ( diff > 7200 ) 	{ info = "2 часа назад "; }
		if ( diff > 10800 ) { info = "3 часа назад "; }
		if ( diff > 14400 ) {
			if ( ( '0' + notiftime.getDate() ).slice( -2 ) === ( '0' + newtime.getDate() ).slice( -2 ) ) {
				info = "сегодня в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " "; 
			} else {
				info = "вчера в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " ";
			}
		}
		if ( diff > 24*3600 ) {
			if ( ('0' + notiftime.getDate()).slice(-2) === ('0' + oldtime.getDate()).slice(-2) ) {
				info = "вчера в " + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + " "; 
			} else {
				info = ('0' + notiftime.getDate()).slice(-2) + ' ' + monthlist[('0' + notiftime.getMonth()).slice(-2)] + ' в ' + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + ' ';
			}
		}
		if ( diff > 2*24*3600 ) { info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let diffrev = notifdate-newdate;
		if ( diff === 0 ) 	{ info = "только что "; }
		if (diffrev > 0) { info = info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' ';
		switch (type) {
      case 'last':
        full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        break;
      case 'MY':
        full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        info = monthlistf[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear();
        break;
      default: break;
    }
    if (time === '0') {
      info = full = empty;
    }
    return( <Tooltip title={full}>{info}</Tooltip> );
  }

  //* Рендер
  render() {
    const spin = <div className="spincenter"><Spin indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />} /></div>;
    const f_Login = (v) => { this.login(v['username'],v['password']); };
    const call_0_1 = (v) => { this.changesett(v); };
    const call_1_1 = (v) => { this.changetab(v); };
    const call_2_1 = (v) => { this.changetab2(v); };
    const call_4_1 = (v) => { this.changetab4(v); };
    const call_5_1 = (v) => { this.changetab5(v); };

    return (
      <ConfigProvider locale={locale}>
        <Layout className={`section_${this.state.activemenu}`}>
          <Router>
            <Suspense fallback={<Layout style={{ minHeight: "100vh" }}><Content></Content></Layout>}>
              {this.state.auth === 0 && <GlobLoadpage />}
              {this.state.auth === 2 && <GlobLeftmenu state={this.state} setState={this.setState.bind(this)} menugo={this.menugo.bind(this)} icons={this.icons} />}
              <Switch>
                {this.state.auth === 1 ? (
                  <>
                    <Route exact path="/">
                      {this.state.auth === 1 && <Redirect to="/login"/> }
                    </Route>
                    <Route exact path="/login" render={(() => (
                      <Auth f_Login={f_Login} />
                    ))} />
                  </>
                ) : (
                  null
                ) }
                {this.state.auth === 2 ? (
                  <>
                    <Route exact path="/login">
                      {this.state.auth === 1 && <Redirect to="/"/> }
                    </Route>
                    <Layout style={{ minHeight: "100vh" }} >
                      <GlobHeader state={this.state} menugo={this.menugo.bind(this)} icons={this.icons} />
                      <Content style={{ margin: '24px 16px 0' }}>
                        {!this.state.loading ?
                          <div className="site-layout-background" style={{ padding: 24 }}>
                            { /* Главная */}
                            <Route exact path="/" render={(() => (
                              <HomeHome state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} icons={this.icons} menulink={this.menulink} />
                            ))} />
                            { /* Задачи */}
                            <Route exact path="/task" render={(() => (
                              <TasksTasks state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} decnum={this.decnum} />
                            ))} />
                            { /* Файлы */}
                            <Route exact path="/files" render={(() => (
                              <ResResources state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} />
                            ))} />
                            { /* Сайты */}
                            <Route exact path="/sites" render={((props) => (
                              <Sites {...props} state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} totime={this.totime} />
                            ))} />
                            { /* Опросы */}
                            <Route exact path="/reviews" render={((props) => (
                              <Reviews {...props} state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} totime={this.totime} />
                            ))} />
                            { /* Новости */}
                            <Route exact path="/news_mz" render={((props) => (
                              <News_mz {...props} state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} totime={this.totime} />
                            ))} />
                            { /* Магазин */}
                            <Route exact path="/clients" render={(() => (
                              <Tabs defaultActiveKey="1" onChange={call_4_1} activeKey={this.state.proj4[0]}>
                                <TabPane tab="Клиенты" key="1">
                                  {this.state.proj4[0] === '1' ? <CliClients        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} /> : spin}
                                </TabPane>
                                <TabPane tab="Сайты" key="2" disabled>
                                  {this.state.proj4[0] === '2' ? <CliSites          state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} /> : spin}
                                </TabPane>
                                <TabPane tab="Соц сети" key="3">
                                  {this.state.proj4[0] === '3' ? <CliSocials        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} /> : spin}
                                </TabPane>
                                <TabPane tab="Витрина услуг" key="4">
                                  {this.state.proj4[0] === '4' ? <CliServices       state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} /> : spin}
                                </TabPane>
                                <TabPane tab="Просмотр сайта" key="5" disabled>
                                  {this.state.proj4[0] === '5' ? <CliView           state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} totime={this.totime} /> : spin}
                                </TabPane>
                                <TabPane tab="Карточка клиента" key="6" disabled>
                                  {this.state.proj4[0] === '6' ? <CliClient         state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} yauploadserv={this.yauploadserv} /> : spin}
                                </TabPane>
                              </Tabs>
                            ))} />
                            { /* Калькулятор */}
                            <Route exact path="/calc" render={(() => (
                              <CalcInst state={this.state} setState={this.setState.bind(this)} modal={this.modal} />
                            ))} />
                            { /* Домены */}
                            <Route exact path="/domains" render={(() => (
                              <DomainsDomains                                       state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} />
                            ))} />
                            { /* Авторство */}
                            <Route exact path="/authors" render={(() => (
                              <AuthorsAuthors state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} />
                            ))} />
                            { /* Магазин */}
                            <Route exact path="/shop" render={(() => (
                              <ShopShop state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} />
                            ))} />
                            { /* Воронки */}
                            <Route exact path="/funnel" render={(() => (
                              <Deals state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} decnum={this.decnum} />
                            ))} />
                            { /* Настройки */}
                            <Route exact path="/settings" render={(() => (
                              <Tabs defaultActiveKey="1" onChange={call_0_1} activeKey={this.state.setting[0]}>
                                <TabPane tab="Профиль" key="1" >
                                  {this.state.setting[0] === '1' ? <SetProfile state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} /> : spin}
                                </TabPane>
                                <TabPane tab="Сотрудники" key="2">
                                  {this.state.setting[0] === '2' ? <SetEmployees          state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} /> : spin}
                                </TabPane>
                                {this.state.profile.type === '1' &&
                                  <TabPane tab="Группы" key="3" >
                                    {this.state.setting[0] === '3' ? <SetGroups               state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} /> : spin}
                                  </TabPane>
                                }
                                {this.state.profile.type === '1' &&
                                  <TabPane tab="Лог" key="4" >
                                    {this.state.setting[0] === '4' ? <SetLogs               state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} /> : spin}
                                  </TabPane>
                                }
                              </Tabs>
                            ))} />
                          </div>
                        :
                          spin
                        }
                      </Content>
                    </Layout>
                  </>
                ) : (
                  null
                ) }
              </Switch>
            </Suspense>
          </Router>
        </Layout>
      </ConfigProvider>
    );
  }
}

export default hot(App);
